import React, { useState, useEffect } from "react";
import Layout from "./Layout";
import axios from "axios";
import { ENDPOINTS } from "../config/apiConfig";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import cookies from "js-cookies";
import { Decrypt, Encrypt } from "../config/cryptor";
import HCaptcha from "@hcaptcha/react-hcaptcha";

const Contact = () => {
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {}, []);

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const userInfo = Decrypt(cookies.getItem("USER-INFO"));

    const formUserInfo = {
      name: name,
      email: email,
      message: message,
      userInfo: userInfo,
    };

    if (isCaptchaVerified) {
      const sendData = async () => {
        const axiosInstance = axios.create({
          baseURL: process.env.REACT_APP_BASE_URL,
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${cookies.getItem("TOKEN")}`,
            "x-access-token": cookies.getItem("TOKEN"),
            "Content-Type": "application/json",
            "X-CSRF-TOKEN": cookies.getItem("X-CSRF-TOKEN"),
          },
        });

        const cryptedData = Encrypt(formUserInfo);

        await axiosInstance
          .post(`${ENDPOINTS.CONTACT}`, {
            data: cryptedData,
          })
          .then((response) => {
            const data = JSON.parse(Decrypt(response.data));
            const error = data.error;
            if (!error.isError) {
              navigate("/contact-success");
            }
          })
          .catch((error) => {
            if (error.response.status === 401) {
              fetchToken();
            }
          });
      };

      const fetchToken = async () => {
        const axiosInstanceApp = axios.create({
          withCredentials: true,
          baseURL: process.env.REACT_APP_BASE_URL,
          headers: {
            Extra: process.env.REACT_APP_EXTRA,
          },
        });

        await axiosInstanceApp
          .get(`${ENDPOINTS.APP}`)
          .then((response) => {
            const data = JSON.parse(Decrypt(response.data));
            cookies.setItem("TOKEN", data.token);
            cookies.setItem("X-CSRF-TOKEN", data.csrf);
            sendData();
          })
          .catch((error) => {
            //console.error("Error fetching token:", error);
          });
      };

      fetchToken();
    } else {
      setError(t("contact.error"));
    }
  };

  const handleCaptchaVerify = () => {
    setIsCaptchaVerified(true);
  };

  return (
    <Layout>
      <div className="p-4 bg-light rounded shadow contact">
        <h1>{t("contact.title")}</h1>
        <form onSubmit={handleFormSubmit}>
          <label htmlFor="name">{t("contact.name")}:</label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />

          <label htmlFor="email">{t("contact.email")}:</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />

          <label htmlFor="message">{t("contact.message")}:</label>
          <textarea
            id="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            rows="4"
            required
          ></textarea>

          <HCaptcha
            sitekey={process.env.REACT_APP_HCAPTCHA_SITE_KEY}
            onVerify={handleCaptchaVerify}
          />

          {error && (
            <div className="container-title">
              <label className="text-danger">{error}</label>
            </div>
          )}

          <button type="submit">{t("contact.send")}</button>
        </form>
      </div>
      <div className="ribbon"></div>
    </Layout>
  );
};

export default Contact;
