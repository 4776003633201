import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import axios from "axios";
import { ENDPOINTS } from "../config/apiConfig";
import { Form, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import Layout from "./Layout";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import cookies from "js-cookies";
import { Decrypt, Encrypt } from "../config/cryptor";
import "react-datepicker/dist/react-datepicker.css";
import { es } from "date-fns/locale/es";

registerLocale("es", es);

const Register = () => {
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();

  const [username, setUsername] = useState("");
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [birthDate, setBirthDate] = useState(new Date());
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [gender, setGender] = useState("");
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [states, setStates] = useState([]);
  const [countries, setCountries] = useState([]);
  const [countriesData, setCountriesData] = useState([]);
  const [currency, setCurrency] = useState("");
  const [isFetchingStates, setIsFetchingStates] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [policyAccepted, setPolicyAccepted] = useState(false);
  const [error, setError] = useState("");
  const [validated, setValidated] = useState([]);
  const [userInfo, setUserInfo] = useState([]);
  const [wait, setWait] = useState(0);
  const [isBanned, setIsBanned] = useState(false);
  const [countdown, setCountdown] = useState("");
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
  const navigate = useNavigate();

  const hcaptchaSiteKey = process.env.REACT_APP_HCAPTCHA_SITE_KEY;

  const genders = [
    t("register.male"),
    t("register.female"),
    t("register.other"),
  ];

  const formattedGenders = genders.map((gender) => ({
    value: `${gender}`,
    label: `${gender}`,
  }));

  const handleTermsCheckboxChange = () => {
    setTermsAccepted(!termsAccepted);
  };

  const handlePolicyCheckboxChange = () => {
    setPolicyAccepted(!policyAccepted);
  };

  const handleCaptchaVerify = () => {
    setIsCaptchaVerified(true);
  };

  const effectRan = useRef(false);

  useEffect(() => {
    const fetchToken = async () => {
      const axiosInstanceApp = axios.create({
        withCredentials: true,
        baseURL: process.env.REACT_APP_BASE_URL,
        headers: {
          Extra: process.env.REACT_APP_EXTRA,
        },
      });

      await axiosInstanceApp
        .get(`${ENDPOINTS.APP}`)
        .then((response) => {
          const data = JSON.parse(Decrypt(response.data));
          cookies.setItem("TOKEN", data.token);
          cookies.setItem("X-CSRF-TOKEN", data.csrf);
          fetchIpBanned();
        })
        .catch((error) => {
          //console.error("Error fetching token:", error);
        });
    };

    const fetchIpBanned = async () => {
      const responseIpInfo = Decrypt(cookies.getItem("USER-INFO"));
      setUserInfo(responseIpInfo.data);

      const axiosInstance = await axios.create({
        baseURL: process.env.REACT_APP_BASE_URL,
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${cookies.getItem("TOKEN")}`,
          "x-access-token": cookies.getItem("TOKEN"),
          "Content-Type": "application/json",
          "X-CSRF-TOKEN": cookies.getItem("X-CSRF-TOKEN"),
        },
      });

      const cryptedData = Encrypt(responseIpInfo);

      await axiosInstance
        .post(`${ENDPOINTS.INTENTION}`, {
          data: cryptedData,
        })
        .then((response) => {
          const data = JSON.parse(Decrypt(response.data));
          const responseError = data;

          if (!responseError.error.isError) {
            setWait(responseError.missingMinutes);

            const endDate = new Date();
            endDate.setMinutes(
              endDate.getMinutes() - responseError.missingMinutes
            );

            const timer = setInterval(() => {
              const now = new Date();
              const remainingTime = endDate.getTime() - now.getTime();
              const minutes = Math.floor(
                (remainingTime % (1000 * 60 * 60)) / (1000 * 60)
              );
              const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);

              if (remainingTime <= 0) {
                clearInterval(timer);
              } else {
                setCountdown(`${minutes}m ${seconds}s`);
              }
            }, 1000);
          } else {
            if (responseError.error.message === "Banned") {
              setIsBanned(true);
            }
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            fetchToken();
          }
        });
    };

    if (effectRan.current) {
      fetchToken();
    }

    return () => (effectRan.current = true);
  }, []);

  useEffect(() => {
    const userInfo = Decrypt(cookies.getItem("USER-INFO"));
    const formUserInfo = {
      email: searchParams.get("email"),
      code: searchParams.get("code"),
      userInfo: userInfo,
    };

    const fetchTokenValidInvitation = async () => {
      const axiosInstanceApp = axios.create({
        withCredentials: true,
        baseURL: process.env.REACT_APP_BASE_URL,
        headers: {
          Extra: process.env.REACT_APP_EXTRA,
        },
      });

      await axiosInstanceApp
        .get(`${ENDPOINTS.APP}`)
        .then((response) => {
          const data = JSON.parse(Decrypt(response.data));
          cookies.setItem("TOKEN", data.token);
          cookies.setItem("X-CSRF-TOKEN", data.csrf);
          fetchValidInvitation();
        })
        .catch((error) => {
          //console.error("Error fetching token:", error);
        });
    };

    const fetchValidInvitation = async () => {
      const axiosInstance = axios.create({
        baseURL: process.env.REACT_APP_BASE_URL,
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${cookies.getItem("TOKEN")}`,
          "x-access-token": cookies.getItem("TOKEN"),
          "X-CSRF-TOKEN": cookies.getItem("X-CSRF-TOKEN"),
          "Content-Type": "application/json",
        },
      });

      const cryptedData = Encrypt(formUserInfo);

      await axiosInstance
        .post(`${ENDPOINTS.GET_INVITATION}`, {
          data: cryptedData,
        })
        .then((response) => {
          const data = JSON.parse(Decrypt(response.data));
          const isValidResponse = data.error;
          setValidated(isValidResponse);
          fetchTokenCountries();
        })
        .catch((error) => {
          if (error.response.status === 401) {
            fetchTokenValidInvitation("valid");
          }
        });
    };

    const fetchTokenCountries = async () => {
      const axiosInstanceApp = axios.create({
        withCredentials: true,
        baseURL: process.env.REACT_APP_BASE_URL,
        headers: {
          Extra: process.env.REACT_APP_EXTRA,
        },
      });

      await axiosInstanceApp
        .get(`${ENDPOINTS.APP}`)
        .then((response) => {
          const data = JSON.parse(Decrypt(response.data));
          cookies.setItem("TOKEN", data.token);
          cookies.setItem("X-CSRF-TOKEN", data.csrf);
          fetchCountries();
        })
        .catch((error) => {
          //console.error("Error fetching token:", error);
        });
    };

    const fetchCountries = async () => {
      const axiosInstance = axios.create({
        baseURL: process.env.REACT_APP_BASE_URL,
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${cookies.getItem("TOKEN")}`,
          "x-access-token": cookies.getItem("TOKEN"),
          "X-CSRF-TOKEN": cookies.getItem("X-CSRF-TOKEN"),
          "Content-Type": "application/json",
        },
      });

      const cryptedData = Encrypt("Americas");

      await axiosInstance
        .post(`${ENDPOINTS.COUNTRIES}`, {
          data: cryptedData,
        })
        .then((response) => {
          const data = JSON.parse(Decrypt(response.data));

          const countriesData = data.countries;
          setCountriesData(countriesData);

          const formattedCountries = countriesData.map((country) => ({
            value: country.name,
            label: (
              <div style={{ display: "flex", alignItems: "center" }}>
                <span className="emoji" title={`${country.name} Flag`}>
                  {country.emoji}
                </span>
                &emsp;
                {country.name}
              </div>
            ),
          }));

          formattedCountries.sort((a, b) => a.value.localeCompare(b.value));
          setCountries(formattedCountries);
        })
        .catch((error) => {
          if (error.response.status === 401) {
            fetchTokenCountries();
          }
        });
    };

    if (!effectRan.current) {
      if (validated.length === 0) {
        if (
          searchParams.get("email") !== null &&
          searchParams.get("code") !== null
        ) {
          fetchTokenValidInvitation();
        }
      }
    }

    return () => (effectRan.current = true);
  }, [validated, searchParams, userInfo]);

  useEffect(() => {
    const fetchStates = async () => {
      const axiosInstance = axios.create({
        baseURL: process.env.REACT_APP_BASE_URL,
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${cookies.getItem("TOKEN")}`,
          "x-access-token": cookies.getItem("TOKEN"),
          "Content-Type": "application/json",
          "X-CSRF-TOKEN": cookies.getItem("X-CSRF-TOKEN"),
        },
      });

      const cryptedData = Encrypt({ country_name: `${selectedCountry.value}` });

      await axiosInstance
        .post(`${ENDPOINTS.STATES}`, {
          data: cryptedData,
        })
        .then((response) => {
          const data = JSON.parse(Decrypt(response.data));
          const countryStates = data.states;
          const formattedStates = Object.keys(countryStates).map((State) => ({
            value: `${countryStates[State].name}`,
            label: `${countryStates[State].name}`,
          }));
          setStates(formattedStates);
        })
        .catch((error) => {
          if (error.response.status === 401) {
            fetchToken();
          }
        })
        .finally(() => {
          setIsFetchingStates(false);
        });
    };

    const fetchToken = async () => {
      const axiosInstanceApp = axios.create({
        withCredentials: true,
        baseURL: process.env.REACT_APP_BASE_URL,
        headers: {
          Extra: process.env.REACT_APP_EXTRA,
        },
      });

      await axiosInstanceApp
        .get(`${ENDPOINTS.APP}`)
        .then((response) => {
          const data = JSON.parse(Decrypt(response.data));
          cookies.setItem("TOKEN", data.token);
          cookies.setItem("X-CSRF-TOKEN", data.csrf);
          fetchStates();
        })
        .catch((error) => {
          //console.error("Error fetching token:", error);
        });
    };

    if (selectedCountry) {
      setCurrency(
        countriesData.find((country) => country.name === selectedCountry.value)
          .currency
      );
      setIsFetchingStates(true);
      setStates([]);
      fetchToken();
      setSelectedState(null);
    } else {
      setStates([]);
    }
  }, [selectedCountry, countriesData]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!username) {
      setError(t("register.errors.username"));
      return;
    }

    if (!name) {
      setError(t("register.errors.name"));
      return;
    }

    if (!lastName) {
      setError(t("register.errors.lastName"));
      return;
    }

    if (!birthDate) {
      setError(t("register.errors.birthDate"));
      return;
    }

    if (!gender) {
      setError(t("register.errors.gender"));
      return;
    }

    if (selectedCountry && selectedState === null && states.length > 0) {
      setError(t("register.errors.selectState"));
      return;
    }

    if (password !== confirmPassword) {
      setError(t("register.errors.passwordNotMatch"));
      return;
    }

    if (!password) {
      setError(t("register.errors.password"));
      return;
    }

    if (!termsAccepted) {
      setError(t("register.errors.acceptTerms"));
      return;
    }

    if (!policyAccepted) {
      setError(t("register.errors.acceptPrivacy"));
      return;
    }

    if (!isCaptchaVerified) {
      setError(t("register.errors.verifyCaptcha"));
      return;
    }

    setError("");

    try {
      const formData = {
        username,
        name,
        lastName,
        birthDate: birthDate.setHours(0, 0, 0, 0),
        gender: gender.value,
        city: selectedState.value,
        country: selectedCountry.value,
        currency,
        email: searchParams.get("email"),
        password,
        code: searchParams.get("code"),
      };

      const cryptedData = Encrypt(formData);

      const fetchToken = async () => {
        const axiosInstanceApp = axios.create({
          withCredentials: true,
          baseURL: process.env.REACT_APP_BASE_URL,
          headers: {
            Extra: process.env.REACT_APP_EXTRA,
          },
        });

        await axiosInstanceApp
          .get(`${ENDPOINTS.APP}`)
          .then((response) => {
            const data = JSON.parse(Decrypt(response.data));
            cookies.setItem("TOKEN", data.token);
            cookies.setItem("X-CSRF-TOKEN", data.csrf);
            sendData();
          })
          .catch((error) => {
            //console.error("Error fetching token:", error);
          });
      };

      const sendData = async () => {
        const axiosInstance = axios.create({
          baseURL: process.env.REACT_APP_BASE_URL,
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${cookies.getItem("TOKEN")}`,
            "x-access-token": cookies.getItem("TOKEN"),
            "Content-Type": "application/json",
            "X-CSRF-TOKEN": cookies.getItem("X-CSRF-TOKEN"),
          },
        });
        await axiosInstance
          .post(`${ENDPOINTS.REGISTER}`, {
            data: cryptedData,
          })
          .then((response) => {
            const data = JSON.parse(Decrypt(response.data));
            const error = data.error;
            if (!error.isError) {
              navigate("/register-success");
            } else {
              setError(error.message);
            }
          });
      };

      fetchToken();
    } catch (error) {
      //console.error("Error al enviar los datos:", error);
    }
  };

  if (!isBanned) {
    if (wait >= 0) {
      if (validated.length !== 0) {
        if (validated.isError) {
          return (
            <div className="container">
              <div className="p-4 bg-light rounded shadow">
                <h4>Error</h4>
                <Form.Text className="text-danger">
                  <p>{t("register.errors.invalidMessage")}</p>
                </Form.Text>
              </div>
            </div>
          );
        } else {
          return (
            <Layout>
              <div className="container">
                <div className="p-4 bg-light rounded shadow register">
                  <Form onSubmit={handleSubmit}>
                    <h2>{t("register.title")}</h2>

                    <div className="register-list">
                      <Form.Group controlId="email" className="form mb-3">
                        <Form.Label>{t("register.email")}</Form.Label>
                        <Form.Control
                          value={searchParams.get("email")}
                          type="text"
                          placeholder={t("register.emailPlaceholder")}
                          required
                          readOnly={true}
                        />
                      </Form.Group>

                      <Form.Group controlId="username" className="form mb-3">
                        <Form.Label>{t("register.username")}</Form.Label>
                        <Form.Control
                          value={username}
                          onChange={(e) => setUsername(e.target.value)}
                          type="text"
                          placeholder={t("register.usernamePlaceholder")}
                          required
                        />
                      </Form.Group>

                      <Form.Group controlId="name" className="form mb-3">
                        <Form.Label>{t("register.name")}</Form.Label>
                        <Form.Control
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          type="text"
                          placeholder={t("register.namePlaceholder")}
                          required
                        />
                      </Form.Group>

                      <Form.Group controlId="lastName" className="form mb-3">
                        <Form.Label>{t("register.lastName")}</Form.Label>
                        <Form.Control
                          value={lastName}
                          onChange={(e) => setLastName(e.target.value)}
                          type="text"
                          placeholder={t("register.lastNamePlaceholder")}
                          required
                        />
                      </Form.Group>

                      <Form.Group controlId="birthDate" className="form mb-3">
                        <Form.Label>{t("register.birthDate")}</Form.Label>
                        <br />
                        <DatePicker
                          className="datepicker"
                          value={birthDate}
                          onChange={(date) => setBirthDate(date)}
                          placeholder={t("register.birthDatePlaceholder")}
                          selected={birthDate}
                          showYearDropdown
                          scrollableMonthYearDropdown
                          locale="es"
                          maxDate={new Date()}
                          showMonthDropdown
                          dropdownMode="select"
                          required
                          placeholderText={t("register.birthDatePlaceholder")}
                        />
                      </Form.Group>

                      <Form.Group controlId="gender" className="form mb-3">
                        <Form.Label>{t("register.gender")}</Form.Label>
                        <Select
                          value={gender}
                          onChange={setGender}
                          options={formattedGenders}
                          placeholder={t("register.genderPlaceholder")}
                          required
                        ></Select>
                      </Form.Group>

                      <Form.Group controlId="country" className="form mb-3">
                        <Form.Label>{t("register.country")}</Form.Label>
                        <Select
                          value={selectedCountry}
                          onChange={setSelectedCountry}
                          options={countries}
                          placeholder={t("register.countryPlaceholder")}
                          required
                        />
                      </Form.Group>

                      <Form.Group controlId="state" className="form mb-3">
                        {selectedCountry && (
                          <div>
                            <Form.Label>{t("register.state")}</Form.Label>
                            {isFetchingStates ? (
                              <p>Loading States...</p>
                            ) : (
                              <Select
                                value={selectedState}
                                onChange={setSelectedState}
                                options={states}
                                placeholder={t("register.statePlaceholder")}
                                required
                              />
                            )}
                          </div>
                        )}
                      </Form.Group>

                      <Form.Group controlId="password" className="form mb-3">
                        <Form.Label>{t("register.password")}</Form.Label>
                        <Form.Control
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          type="password"
                          placeholder={t("register.passwordPlaceholder")}
                          required
                        />
                      </Form.Group>

                      <Form.Group
                        controlId="confirmPassword"
                        className="form mb-3"
                      >
                        <Form.Label>{t("register.confirmPassword")}</Form.Label>
                        <Form.Control
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          type="password"
                          placeholder={t("register.confirmPasswordPlaceholder")}
                          required
                        />
                      </Form.Group>

                      <Form.Group
                        controlId="termsCheckbox"
                        className="form mb-3"
                      >
                        <Form.Check
                          type="checkbox"
                          label={
                            <>
                              {t("register.readAndAgreeTerms")}{" "}
                              <a href="/terms" target="_blank">
                                {t("footer.terms")}
                              </a>
                            </>
                          }
                          checked={termsAccepted}
                          onChange={handleTermsCheckboxChange}
                        />
                      </Form.Group>

                      <Form.Group
                        controlId="policyCheckbox"
                        className="form mb-3"
                      >
                        <Form.Check
                          type="checkbox"
                          label={
                            <>
                              {t("register.readAndAcceptPrivacyPolicy")}{" "}
                              <a href="/privacy" target="_blank">
                                {t("footer.privacy")}
                              </a>
                            </>
                          }
                          checked={policyAccepted}
                          onChange={handlePolicyCheckboxChange}
                        />
                      </Form.Group>
                      <Form.Group
                        controlId="verifyCaptcha"
                        className="form mb-3"
                      >
                        <HCaptcha
                          sitekey={hcaptchaSiteKey}
                          onVerify={handleCaptchaVerify}
                        />
                      </Form.Group>

                      {error && (
                        <div className="container-title">
                          <Form.Text className="text-danger">{error}</Form.Text>
                        </div>
                      )}
                      <Button
                        variant="success"
                        type="submit"
                        className="w-100 btn btn-lg btn-primary"
                      >
                        {t("register.registerButton")}
                      </Button>
                    </div>
                  </Form>
                </div>
                <div className="ribbon"> </div>
              </div>
            </Layout>
          );
        }
      } else {
        return (
          <div className="container">
            <div className="p-4 bg-light rounded shadow">
              <h4>Error</h4>
              <Form.Text className="text-danger">
                <p>{t("register.errors.invalidMessage")}</p>
              </Form.Text>
            </div>
          </div>
        );
      }
    } else {
      return (
        <div className="container">
          <div className="p-4 bg-light rounded shadow">
            <h4>Error</h4>
            <Form.Text className="text-danger">
              <p>
                {t("register.errors.tryAgainIn")} {countdown}
              </p>
            </Form.Text>
          </div>
        </div>
      );
    }
  } else {
    return (
      <div className="container">
        <div className="p-4 bg-light rounded shadow">
          <h4>Error</h4>
          <Form.Text className="text-danger">
            <p>{t("register.errors.banned")}</p>
          </Form.Text>
        </div>
      </div>
    );
  }
};

export default Register;
