import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Home from "./components/Home";
import LandingPage from "./components/LandingPage";
import About from "./components/About";
import TermsOfService from "./components/TermsOfService";
import PrivacyPolicy from "./components/PrivacyPolicy";
import CookiePolicy from "./components/CookiePolicy";
import Contact from "./components/Contact";
import ContactSuccess from "./components/ContactSuccess";
import Footer from "./components/Footer";
import Header from "./components/Header";
import PreRegister from "./components/PreRegister";
import PreRegisterSuccess from "./components/PreRegisterSuccess";
import CookieConsentForm from "./components/CookieConsentForm";
import ScrollToTop from "./components/ScrollToTop";
import NotFound from "./components/NotFound";
import Register from "./components/Register";
import RegisterSuccess from "./components/RegisterSuccess";
import Login from "./components/Login";
import Account from "./components/Account";
import RecoveryPassword from "./components/RecoveryPassword";
import RecoveryPasswordSuccess from "./components/RecoveryPasswordSuccess";
import SurveyForm from "./components/SurveyForm";
import SurveyFormSuccess from "./components/SurveyFormSuccess";

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Header />
      <div className="App">
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/terms" element={<TermsOfService />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/cookies" element={<CookiePolicy />} />
          <Route path="/cookie-consent-form" element={<CookieConsentForm />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/contact-success" element={<ContactSuccess />} />
          <Route path="/pre-register" element={<PreRegister />} />
          <Route
            path="/pre-register-success"
            element={<PreRegisterSuccess />}
          />
          <Route path="/register" element={<Register />} />
          <Route path="/register-success" element={<RegisterSuccess />} />
          <Route path="/landing" element={<LandingPage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/account" element={<Account />} />
          <Route path="/recovery-password" element={<RecoveryPassword />} />
          <Route
            path="/recovery-success"
            element={<RecoveryPasswordSuccess />}
          />
          <Route path="/survey-form" element={<SurveyForm />} />
          <Route path="/survey-form-success" element={<SurveyFormSuccess />} />
          <Route path="/404" element={<NotFound />} />
          <Route path="*" element={<Navigate to="/404" />} />
        </Routes>
      </div>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
