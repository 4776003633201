import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import Layout from "./Layout";

function RecoveryPasswordSuccess() {
  const { t } = useTranslation();

  return (
    <Layout>
      <div className="p-4 rounded shadow">
        <div className="registration-success">
          <h2>{t("recoverySuccess.title")}</h2>
          <p>{t("recoverySuccess.message")}</p>
          <Link to="/">{t("common.backToHome")}</Link>
        </div>
      </div>
      <div className="ribbon"></div>
    </Layout>
  );
}

export default RecoveryPasswordSuccess;
