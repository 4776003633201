export function getAccountErrorValue(value) {
  let result;

  switch (value) {
    case "USER_NOT_EXISTS":
      result = "account.errors.userNotExists";
      break;
    case "PASSWORD_INCORRECT":
      result = "account.errors.passwordIncorrect";
      break;
    case "USER_BANNED":
      result = "account.errors.userBanned";
      break;
    default:
      result = "account.errors.undefined";
      break;
  }

  return result;
}
