import React from "react";
import Layout from "./Layout";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function TermsOfService() {
  const { t } = useTranslation();
  return (
    <Layout>
      <div className="container">
        <div className="p-4 bg-light rounded shadow terms-of-use">
          <h1 className="container-title">{t("termsOfService.title")}</h1>
          <p>{t("termsOfService.effectDate")}</p>
          <p>{t("termsOfService.welcome")}</p>
          <ol>
            <li>
              <h3> {t("termsOfService.title1")}</h3>
              <p>{t("termsOfService.title1desc1")}</p>
              <p>{t("termsOfService.title1desc2")}</p>
              <p>{t("termsOfService.title1desc3")}</p>
            </li>
            <li>
              <h3> {t("termsOfService.title2")}</h3>
              <p>{t("termsOfService.title2desc1")}</p>
              <p>{t("termsOfService.title2desc2")}</p>
              <p>{t("termsOfService.title2desc3")}</p>
            </li>
            <li>
              <h3> {t("termsOfService.title3")}</h3>
              <p>{t("termsOfService.title3desc1")}</p>
              <p>{t("termsOfService.title3desc2")}</p>
            </li>
            <li>
              <h3> {t("termsOfService.title4")}</h3>
              <p>{t("termsOfService.title4desc1")}</p>
              <p>{t("termsOfService.title4desc2")}</p>
            </li>
            <li>
              <h3> {t("termsOfService.title5")}</h3>
              <p>{t("termsOfService.title5desc1")}</p>
              <p>{t("termsOfService.title5desc2")}</p>
            </li>
            <li>
              <h3> {t("termsOfService.title6")}</h3>
              <p>{t("termsOfService.title6desc1")}</p>
            </li>
            <li>
              <h3> {t("termsOfService.title7")}</h3>
              <p>{t("termsOfService.title7desc1")}</p>
            </li>
            <li>
              <h3> {t("termsOfService.title8")}</h3>
              <p>{t("termsOfService.title8desc1")}</p>
            </li>
            <li>
              <h3> {t("termsOfService.title9")}</h3>
              <p>{t("termsOfService.title9desc1")}</p>
            </li>
          </ol>
          <p>
            {t("termsOfService.title10desc1")}{" "}
            <Link to="/contact">{t("footer.contact")}</Link>
          </p>
          <p>{t("termsOfService.acceptByUse")}</p>
        </div>
        <div className="ribbon"></div>
      </div>
    </Layout>
  );
}

export default TermsOfService;
