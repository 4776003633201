import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import axios from "axios";
import { ENDPOINTS } from "../config/apiConfig";
import { Form, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Layout from "./Layout";
import cookies from "js-cookies";
import { Decrypt, Encrypt } from "../config/cryptor";
import HCaptcha from "@hcaptcha/react-hcaptcha";

const PreRegister = () => {
  const { t } = useTranslation();

  const [email, setEmail] = useState("");
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [states, setStates] = useState([]);
  const [countries, setCountries] = useState([]);
  const [selectedColony, setSelectedColony] = useState(null);
  const [isFetchingStates, setIsFetchingStates] = useState(false);
  const [zipCode, setZipCode] = useState("");
  const [colonies, setColonies] = useState([]);
  const [loading, setLoading] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [policyAccepted, setPolicyAccepted] = useState(false);
  const [howDidYouHear, setHowDidYouHear] = useState(null);
  const [additionalInfo, setAdditionalInfo] = useState("");
  const [showAdditionalInfo, setShowAdditionalInfo] = useState(false);
  const [error, setError] = useState("");

  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);

  const navigate = useNavigate();

  const handleTermsCheckboxChange = () => {
    setTermsAccepted(!termsAccepted);
  };

  const handlePolicyCheckboxChange = () => {
    setPolicyAccepted(!policyAccepted);
  };

  const handleHowDidYouHearChange = (selectedOption) => {
    setHowDidYouHear(selectedOption);
    setShowAdditionalInfo(selectedOption?.value === "other" ? true : false);
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(email);
  };

  const handleCaptchaVerify = () => {
    setIsCaptchaVerified(true);
  };

  const effectRan = useRef(false);
  useEffect(() => {
    const fetchToken = async () => {
      const axiosInstanceApp = axios.create({
        withCredentials: true,
        baseURL: process.env.REACT_APP_BASE_URL,
        headers: {
          Extra: process.env.REACT_APP_EXTRA,
        },
      });

      await axiosInstanceApp
        .get(`${ENDPOINTS.APP}`)
        .then((response) => {
          const data = JSON.parse(Decrypt(response.data));
          cookies.setItem("TOKEN", data.token);
          cookies.setItem("X-CSRF-TOKEN", data.csrf);
          fetchCountries();
        })
        .catch((error) => {
          //console.error("Error fetching token:", error);
        });
    };

    const fetchCountries = async () => {
      const axiosInstance = axios.create({
        baseURL: process.env.REACT_APP_BASE_URL,
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${cookies.getItem("TOKEN")}`,
          "x-access-token": cookies.getItem("TOKEN"),
          "X-CSRF-TOKEN": cookies.getItem("X-CSRF-TOKEN"),
          "Content-Type": "application/json",
        },
      });

      const cryptedData = Encrypt("Americas");
      await axiosInstance
        .post(`${ENDPOINTS.COUNTRIES}`, {
          data: cryptedData,
        })
        .then((response) => {
          const data = JSON.parse(Decrypt(response.data));
          const countriesData = data.countries;
          const formattedCountries = countriesData.map((country) => ({
            value: country.name,
            label: (
              <div style={{ display: "flex", alignItems: "center" }}>
                <span className="emoji" title={`${country.name} Flag`}>
                  {country.emoji}
                </span>
                &emsp;
                {country.name}
              </div>
            ),
          }));

          formattedCountries.sort((a, b) => a.value.localeCompare(b.value));
          setCountries(formattedCountries);
        })
        .catch((error) => {
          //console.error("Error fetching countries");
        });
    };

    if (!effectRan.current) {
      fetchToken();
    }

    return () => (effectRan.current = true);
  }, []);

  useEffect(() => {
    const fetchStates = async () => {
      const axiosInstance = axios.create({
        baseURL: process.env.REACT_APP_BASE_URL,
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${cookies.getItem("TOKEN")}`,
          "x-access-token": cookies.getItem("TOKEN"),
          "Content-Type": "application/json",
          "X-CSRF-TOKEN": cookies.getItem("X-CSRF-TOKEN"),
        },
      });

      const cryptedData = Encrypt({ country_name: `${selectedCountry.value}` });

      await axiosInstance
        .post(`${ENDPOINTS.STATES}`, {
          data: cryptedData,
        })
        .then((response) => {
          const data = JSON.parse(Decrypt(response.data));
          const countryStates = data.states;
          const formattedStates = Object.keys(countryStates).map((State) => ({
            value: `${countryStates[State].name}`,
            label: `${countryStates[State].name}`,
          }));
          setStates(formattedStates);
        })
        .catch((error) => {
          if (error.response.status === 401) {
            fetchToken();
          }
        })
        .finally(() => {
          setIsFetchingStates(false);
        });
    };

    const fetchToken = async () => {
      const axiosInstanceApp = axios.create({
        withCredentials: true,
        baseURL: process.env.REACT_APP_BASE_URL,
        headers: {
          Extra: process.env.REACT_APP_EXTRA,
        },
      });

      await axiosInstanceApp
        .get(`${ENDPOINTS.APP}`)
        .then((response) => {
          const data = JSON.parse(Decrypt(response.data));
          cookies.setItem("TOKEN", data.token);
          cookies.setItem("X-CSRF-TOKEN", data.csrf);
          fetchStates();
        })
        .catch((error) => {
          //console.error("Error fetching token:", error);
        });
    };

    if (selectedCountry) {
      setIsFetchingStates(true);
      setStates([]);
      fetchStates();
      setSelectedState(null);
    } else {
      setStates([]);
    }
  }, [selectedCountry]);

  useEffect(() => {
    const fetchColonies = async () => {
      if (zipCode.length === 5) {
        setSelectedColony("");
        //zipCode.length === 4 ||
        const axiosInstance = axios.create({
          baseURL: process.env.REACT_APP_BASE_URL,
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${cookies.getItem("TOKEN")}`,
            "x-access-token": cookies.getItem("TOKEN"),
            "Content-Type": "application/json",
            "X-CSRF-TOKEN": cookies.getItem("X-CSRF-TOKEN"),
          },
        });
        setLoading(true);
        try {
          const cryptedData = Encrypt({
            d_codigo: `${zipCode}`,
            d_estado: `${selectedState.value}`,
          });
          const response = await axiosInstance.post(
            `${ENDPOINTS.POSTAL_CODE}`,
            {
              data: cryptedData,
            }
          );
          const postal = JSON.parse(Decrypt(response.data));
          const data = postal.postalCodes;
          const formattedColonies = data.map((colony) => ({
            value: colony._id,
            label: colony.d_asenta,
          }));
          setColonies(formattedColonies);
        } catch (error) {
          if (error.response.status === 401) {
            fetchToken();
          }
        } finally {
          setLoading(false);
        }
      } else {
        setColonies([]);
      }
    };

    const fetchToken = async () => {
      const axiosInstanceApp = axios.create({
        withCredentials: true,
        baseURL: process.env.REACT_APP_BASE_URL,
        headers: {
          Extra: process.env.REACT_APP_EXTRA,
        },
      });

      await axiosInstanceApp
        .get(`${ENDPOINTS.APP}`)
        .then((response) => {
          const data = JSON.parse(Decrypt(response.data));
          cookies.setItem("TOKEN", data.token);
          cookies.setItem("X-CSRF-TOKEN", data.csrf);
          fetchColonies();
        })
        .catch((error) => {
          //console.error("Error fetching token:", error);
        });
    };

    if (selectedState) {
      setColonies([]);
      fetchColonies();
    } else {
      setColonies([]);
    }
  }, [zipCode, selectedState]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isValidEmail(email)) {
      setError(t("preRegister.errors.invalidEmail"));
      return;
    }

    if (selectedCountry && selectedState === null && states.length > 0) {
      setError(t("preRegister.errors.selectState"));
      return;
    }

    if (selectedCountry.value === "Mexico") {
      if (zipCode === "") {
        setError(t("preRegister.errors.enterZipCode"));
        return;
      }

      if (zipCode !== "" && colonies.length === 0) {
        setError(t("preRegister.errors.enterValidZipCode"));
        return;
      }

      if (!selectedColony) {
        setError(t("preRegister.errors.selectColony"));
        return;
      }
    }

    if (howDidYouHear.value === "other" && additionalInfo === "") {
      setError(t("preRegister.errors.enterAdditionalInfo"));
      return;
    }

    if (!termsAccepted) {
      setError(t("preRegister.errors.acceptTerms"));
      return;
    }

    if (!policyAccepted) {
      setError(t("preRegister.errors.acceptPrivacy"));
      return;
    }

    if (!isCaptchaVerified) {
      setError(t("preRegister.errors.verifyCaptcha"));
      return;
    }

    const userInfo = Decrypt(cookies.getItem("USER-INFO"));

    const formData = {
      email,
      selectedCountry,
      selectedState,
      zipCode,
      selectedColony,
      howDidYouHear,
      additionalInfo,
      userInfo: userInfo,
    };

    const cryptedData = Encrypt(formData);

    setError("");

    try {
      const fetchToken = async () => {
        const axiosInstanceApp = axios.create({
          withCredentials: true,
          baseURL: process.env.REACT_APP_BASE_URL,
          headers: {
            Extra: process.env.REACT_APP_EXTRA,
          },
        });

        await axiosInstanceApp
          .get(`${ENDPOINTS.APP}`)
          .then((response) => {
            const data = JSON.parse(Decrypt(response.data));
            cookies.setItem("TOKEN", data.token);
            cookies.setItem("X-CSRF-TOKEN", data.csrf);
            sendData();
          })
          .catch((error) => {
            //console.error("Error fetching token:", error);
          });
      };

      const sendData = async () => {
        const axiosInstance = axios.create({
          baseURL: process.env.REACT_APP_BASE_URL,
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${cookies.getItem("TOKEN")}`,
            "x-access-token": cookies.getItem("TOKEN"),
            "Content-Type": "application/json",
            "X-CSRF-TOKEN": cookies.getItem("X-CSRF-TOKEN"),
          },
        });
        await axiosInstance
          .post(`${ENDPOINTS.PRE_REGISTER}`, {
            data: cryptedData,
          })
          .then((response) => {
            const data = JSON.parse(Decrypt(response.data));
            const error = data.error;

            if (!error.isError) {
              navigate("/pre-register-success");
            }
          });
      };
      fetchToken();
    } catch (error) {
      //console.error("Error al enviar los datos:", error);
    }
  };

  return (
    <Layout>
      <div className="container">
        <div className="p-4 bg-light rounded shadow">
          <Form onSubmit={handleSubmit}>
            <h2>{t("preRegister.title")}</h2>

            <div className="register-list">
              <Form.Group controlId="email" className="form mb-3">
                <Form.Label>{t("preRegister.email")}</Form.Label>
                <Form.Control
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  placeholder={t("preRegister.emailPlaceholder")}
                  required
                />
              </Form.Group>

              <Form.Group controlId="country" className="form mb-3">
                <Form.Label>{t("preRegister.country")}</Form.Label>
                <Select
                  value={selectedCountry}
                  onChange={setSelectedCountry}
                  options={countries}
                  placeholder={t("preRegister.countryPlaceholder")}
                  required
                />
              </Form.Group>

              <Form.Group controlId="state" className="form mb-3">
                {selectedCountry && (
                  <div>
                    <Form.Label>{t("preRegister.state")}</Form.Label>
                    {isFetchingStates ? (
                      <p>Loading States...</p>
                    ) : (
                      <Select
                        value={selectedState}
                        onChange={setSelectedState}
                        options={states}
                        placeholder={t("preRegister.statePlaceholder")}
                      />
                    )}
                  </div>
                )}
              </Form.Group>

              {selectedCountry !== null &&
                selectedCountry.value === "Mexico" && (
                  <div>
                    <Form.Group controlId="zipCode" className="form mb-3">
                      <Form.Label>{t("preRegister.zipCode")}</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={t("preRegister.zipCodePlaceholder")}
                        value={zipCode}
                        onChange={(e) => setZipCode(e.target.value)}
                        maxLength={5}
                      />
                    </Form.Group>
                    <Form.Group controlId="colonies" className="form mb-3">
                      {loading ? (
                        <p>{t("preRegister.loadingColonies")}</p>
                      ) : (
                        colonies.length > 0 && (
                          <div>
                            <Form.Label>{t("preRegister.colonies")}</Form.Label>
                            <Select
                              value={selectedColony}
                              onChange={setSelectedColony}
                              options={colonies}
                              placeholder={t("preRegister.coloniesPlaceholder")}
                            />
                          </div>
                        )
                      )}
                    </Form.Group>
                  </div>
                )}

              <Form.Group controlId="howDidYouHear" className="form mb-3">
                <Form.Label>{t("preRegister.howDidYouHear")}</Form.Label>
                <Select
                  value={howDidYouHear}
                  onChange={handleHowDidYouHearChange}
                  placeholder={t("preRegister.howDidYouHearPlaceholder")}
                  options={[
                    {
                      value: "socialMedia",
                      label: t("preRegister.socialMedia"),
                    },
                    {
                      value: "friendRecommendation",
                      label: t("preRegister.friendRecommendation"),
                    },
                    {
                      value: "onlineSearch",
                      label: t("preRegister.onlineSearch"),
                    },
                    {
                      value: "advertisement",
                      label: t("preRegister.advertisement"),
                    },
                    { value: "other", label: t("preRegister.other") },
                  ]}
                  required
                />
              </Form.Group>

              {showAdditionalInfo && (
                <Form.Group controlId="additionalInfo" className="form mb-3">
                  <Form.Label>{t("preRegister.additionalInfo")}</Form.Label>
                  <Form.Control
                    as="textarea"
                    value={additionalInfo}
                    onChange={(e) => setAdditionalInfo(e.target.value)}
                  />
                </Form.Group>
              )}

              <Form.Group controlId="termsCheckbox" className="form mb-3">
                <Form.Check
                  type="checkbox"
                  label={
                    <>
                      {t("preRegister.readAndAgreeTerms")}{" "}
                      <a href="/terms" target="_blank">
                        {t("footer.terms")}
                      </a>
                    </>
                  }
                  checked={termsAccepted}
                  onChange={handleTermsCheckboxChange}
                />
              </Form.Group>

              <Form.Group controlId="policyCheckbox" className="form mb-3">
                <Form.Check
                  type="checkbox"
                  label={
                    <>
                      {t("preRegister.readAndAcceptPrivacyPolicy")}{" "}
                      <a href="/privacy" target="_blank">
                        {t("footer.privacy")}
                      </a>
                    </>
                  }
                  checked={policyAccepted}
                  onChange={handlePolicyCheckboxChange}
                />
              </Form.Group>
              <Form.Group controlId="verifyCaptcha" className="form mb-3">
                <HCaptcha
                  sitekey={process.env.REACT_APP_HCAPTCHA_SITE_KEY}
                  onVerify={handleCaptchaVerify}
                />
              </Form.Group>
              {error && (
                <div className="container-title">
                  <Form.Text className="text-danger">{error}</Form.Text>
                </div>
              )}
              <Button
                variant="success"
                type="submit"
                className="w-100 btn btn-lg btn-primary"
              >
                {t("preRegister.registerButton")}
              </Button>
            </div>
          </Form>
        </div>
        <div className="ribbon"> </div>
      </div>
    </Layout>
  );
};

export default PreRegister;
