import React from "react";
import Layout from "./Layout";
import logoPlaceholder from "../assets/images/logo.png";
import WaitingList from "../components/WaitingList";
import { useTranslation } from "react-i18next";

const Home = () => {
  const { t } = useTranslation();
  return (
    <Layout>
      <div className="container">
        <div className="center-content">
          <div className="image-column">
            <img src={logoPlaceholder} alt="Placeholder" className="img" />
            <div className="ribbon slogan"></div>
            <h3>{t("home.slogan")}</h3>
          </div>
          <div className="auth-column pre-register">
            <WaitingList />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Home;
