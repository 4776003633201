import React from "react";
import Layout from "./Layout";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function PrivacyPolicy() {
  const { t } = useTranslation();
  return (
    <Layout>
      <div className="container">
        <div className="p-4 bg-light rounded shadow privacy-policy">
          <h1 className="container-title">{t("privacyPolicy.title")}</h1>
          <p>{t("privacyPolicy.effectDate")}</p>
          <p>{t("privacyPolicy.welcome")}</p>
          <ol>
            <li>
              <h3>{t("privacyPolicy.title1")}</h3>
              <p>{t("privacyPolicy.title1desc1")}</p>
              <p>{t("privacyPolicy.title1desc2")}</p>
              <p>{t("privacyPolicy.title1desc3")}</p>
              <p>{t("privacyPolicy.title1desc4")}</p>
              <p>{t("privacyPolicy.title1desc5")}</p>
            </li>
            <li>
              <h3>{t("privacyPolicy.title2")}</h3>
              <p>{t("privacyPolicy.title2desc1")}</p>
              <p>{t("privacyPolicy.title2desc2")}</p>
              <p>{t("privacyPolicy.title2desc3")}</p>
            </li>
            <li>
              <h3>{t("privacyPolicy.title3")}</h3>
              <p>{t("privacyPolicy.title3desc1")}</p>
              <p>{t("privacyPolicy.title3desc2")}</p>
              <p>{t("privacyPolicy.title3desc3")}</p>
            </li>
            <li>
              <h3>{t("privacyPolicy.title4")}</h3>
              <p>{t("privacyPolicy.title4desc1")}</p>
              <p>{t("privacyPolicy.title4desc2")}</p>
            </li>
            <li>
              <h3>{t("privacyPolicy.title5")}</h3>
              <p>{t("privacyPolicy.title5desc1")}</p>
              <p>{t("privacyPolicy.title5desc2")}</p>
            </li>
            <li>
              <h3>{t("privacyPolicy.title6")}</h3>
              <p>{t("privacyPolicy.title6desc1")}</p>
            </li>
            <li>
              <h3>{t("privacyPolicy.title7")}</h3>
              <p>{t("privacyPolicy.title7desc1")}</p>
            </li>
            <li>
              <h3>{t("privacyPolicy.title8")}</h3>
              <p>
                {t("privacyPolicy.title8desc1")}{" "}
                <Link to="/contact">{t("footer.contact")}</Link>
              </p>
            </li>
          </ol>
          <p>{t("privacyPolicy.acceptByUse")}</p>
        </div>
        <div className="ribbon"></div>
      </div>
    </Layout>
  );
}

export default PrivacyPolicy;
