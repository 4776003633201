import React from "react";
import Layout from "./Layout";
import { useTranslation } from "react-i18next";

function About() {
  const { t } = useTranslation();
  return (
    <Layout>
      <div className="p-4 bg-light rounded shadow about">
        <h1>{t("about.title")}</h1>
        <p>{t("about.desc1")}</p>
        <p>{t("about.desc2")}</p>
        <p>{t("about.desc3")}</p>
      </div>
      <div className="ribbon"></div>
    </Layout>
  );
}

export default About;
