import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import Modal from "react-modal";
import { ENDPOINTS } from "../config/apiConfig";
import { useNavigate } from "react-router-dom";
import Layout from "./Layout";
import { useTranslation } from "react-i18next";
import cookies from "js-cookies";
import { Decrypt, Encrypt } from "../config/cryptor";
import { getAccountErrorValue } from "../tools/errorDictionary";

const Account = () => {
  const { t } = useTranslation();

  const [userInfo, setUserInfo] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const navigate = useNavigate();

  const logout = () => {
    cookies.setItem("_data", "");
    navigate("/");
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const fetchIp = async () => {
    const responseIpInfo = await axios.get("https://ipinfo.io/json");
    setUserInfo(responseIpInfo.data.loc);

    const data = await Encrypt(responseIpInfo.data);
    cookies.setItem("USER-INFO", data);
  };

  const effectRan = useRef(false);

  useEffect(() => {
    const data = cookies.getItem("_data");
    if (data) {
      const decryptedEmail = Decrypt(data);
      setEmail(decryptedEmail);
    }

    return () => (effectRan.current = true);
  }, []);

  useEffect(() => {
    if (cookies.getItem("USER-INFO") === null || userInfo.length === 0) {
      if (userInfo.length === 0) {
        fetchIp();
      }
    }
  }, [userInfo]);

  async function send() {
    if (!password) {
      setError(t("account.errors.password"));

      return;
    }
    const coordenadasArrayStr = userInfo.split(",");
    const coordenadasArrayNum = coordenadasArrayStr.map((coord) =>
      parseFloat(coord)
    );

    const formData = {
      email,
      password,
      location: {
        type: "Point",
        coordinates: coordenadasArrayNum,
      },
    };
    setError("");

    const cryptedData = Encrypt(formData);
    const fetchToken = async () => {
      const axiosInstanceApp = axios.create({
        withCredentials: true,
        baseURL: process.env.REACT_APP_BASE_URL,
        headers: {
          Extra: process.env.REACT_APP_EXTRA,
        },
      });

      await axiosInstanceApp
        .get(`${ENDPOINTS.APP}`)
        .then((response) => {
          const data = JSON.parse(Decrypt(response.data));
          cookies.setItem("TOKEN", data.token);
          cookies.setItem("X-CSRF-TOKEN", data.csrf);
          sendData();
        })
        .catch((error) => {
          //console.error("Error fetching token:", error);
        });
    };

    const sendData = async () => {
      const axiosInstance = axios.create({
        baseURL: process.env.REACT_APP_BASE_URL,
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${cookies.getItem("TOKEN")}`,
          "x-access-token": cookies.getItem("TOKEN"),
          "Content-Type": "application/json",
          "X-CSRF-TOKEN": cookies.getItem("X-CSRF-TOKEN"),
        },
      });
      await axiosInstance
        .post(`${ENDPOINTS.UNSUSCRIBE}`, {
          data: cryptedData,
        })
        .then((response) => {
          const data = JSON.parse(Decrypt(response.data));
          const error = data.error;

          if (!error.isError) {
            cookies.setItem("_data", "");
            navigate("/");
          } else {
            setError(t(getAccountErrorValue(error.message)));
          }
        });
    };

    fetchToken();
  }

  const handleFormSubmit = async (e) => {
    e.preventDefault();
  };

  if (email) {
    return (
      <Layout>
        <Modal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          className="ReactModal__Confirm"
          contentLabel={t("account.reallyDelete")}
        >
          <h2>{t("account.confirmDelete")}</h2>
          <p>{t("account.notRecover")}</p>
          <div className="input-container">
            <span className="input-label">{t("account.password")}</span>

            <input
              type="password"
              id="password"
              value={password}
              placeholder={t("account.passwordPlaceholder")}
              onChange={(e) => setPassword(e.target.value)}
              className="input-field"
              required
            />
          </div>

          {error && (
            <div className="container-title">
              <label className="text-danger">{error}</label>
            </div>
          )}

          <div className="modal-buttons">
            <button
              className="w-100 btn btn-lg btn-primary"
              onClick={closeModal}
            >
              {t("account.cancel")}
            </button>
            <button className="w-100 btn btn-lg btn-danger" onClick={send}>
              {t("account.confirm")}
            </button>
          </div>
        </Modal>
        <form onSubmit={handleFormSubmit}>
          <div className="p-4 bg-light rounded shadow about">
            <h1>{t("account.miAccount")}</h1>

            <p>
              {t("account.grettings")} {email} !
            </p>
            <div className="button-container">
              <button
                variant="success"
                type="submit"
                onClick={openModal}
                className="w-100 btn btn-lg btn-danger"
              >
                {t("account.deleteAccount")}
              </button>

              <button
                style={{ paddingBottom: "10px" }}
                variant="success"
                type="submit"
                onClick={logout}
                className="w-100 btn btn-lg btn-primary"
              >
                {t("account.closeSession")}
              </button>
            </div>
          </div>
        </form>
        <div className="ribbon"></div>
      </Layout>
    );
  } else {
    navigate("/login");
  }
};

export default Account;
