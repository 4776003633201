import React from "react";
import Layout from "./Layout";

function LandingPage() {
  return (
    <Layout>

        <h1>Landing Page</h1>
        

    </Layout>
  );
}

export default LandingPage;