import React, { useEffect, useState } from "react";
import axios from "axios";
import { ENDPOINTS } from "../config/apiConfig";
import { useNavigate } from "react-router-dom";
import Layout from "./Layout";
import { useTranslation } from "react-i18next";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import cookies from "js-cookies";
import { Decrypt, Encrypt } from "../config/cryptor";
import { getAccountErrorValue } from "../tools/errorDictionary";

const Login = () => {
  const { t } = useTranslation();

  const [userInfo, setUserInfo] = useState([]);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);

  const navigate = useNavigate();

  const hcaptchaSiteKey = process.env.REACT_APP_HCAPTCHA_SITE_KEY;

  const handleCaptchaVerify = () => {
    setIsCaptchaVerified(true);
  };

  const fetchIp = async () => {
    const responseIpInfo = await axios.get("https://ipinfo.io/json");
    setUserInfo(responseIpInfo.data.loc);

    const data = await Encrypt(responseIpInfo.data);
    cookies.setItem("USER-INFO", data);
  };

  useEffect(() => {
    if (cookies.getItem("USER-INFO") === null) {
      if (userInfo.length === 0) {
        fetchIp();
      }
    }
  }, [userInfo]);

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (!email) {
      setError(t("register.errors.username"));
      return;
    }

    if (!password) {
      setError(t("register.errors.passwordNotMatch"));
      return;
    }

    if (!isCaptchaVerified) {
      setError(t("register.errors.verifyCaptcha"));
      return;
    }

    setError("");

    try {
      const formData = {
        email,
        password,
      };

      const cryptedData = Encrypt(formData);

      const fetchToken = async () => {
        const axiosInstanceApp = axios.create({
          withCredentials: true,
          baseURL: process.env.REACT_APP_BASE_URL,
          headers: {
            Extra: process.env.REACT_APP_EXTRA,
          },
        });

        await axiosInstanceApp
          .get(`${ENDPOINTS.APP}`)
          .then((response) => {
            const data = JSON.parse(Decrypt(response.data));
            cookies.setItem("TOKEN", data.token);
            cookies.setItem("X-CSRF-TOKEN", data.csrf);
            sendData();
          })
          .catch((error) => {
            //console.error("Error fetching token:", error);
          });
      };

      const sendData = async () => {
        const axiosInstance = axios.create({
          baseURL: process.env.REACT_APP_BASE_URL,
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${cookies.getItem("TOKEN")}`,
            "x-access-token": cookies.getItem("TOKEN"),
            "Content-Type": "application/json",
            "X-CSRF-TOKEN": cookies.getItem("X-CSRF-TOKEN"),
          },
        });
        await axiosInstance
          .post(`${ENDPOINTS.LOGIN}`, {
            data: cryptedData,
          })
          .then((response) => {
            const data = JSON.parse(Decrypt(response.data));
            const error = data.error;
            const cryptedEmail = Encrypt(email);
            if (!error.isError) {
              cookies.setItem("_data", cryptedEmail);
              navigate("/account");
            } else {
              setError(t(getAccountErrorValue(error.message)));
            }
          });
      };

      fetchToken();
    } catch (error) {
      //console.error("Error al enviar los datos:", error);
    }
  };

  return (
    <Layout>
      <div className="p-4 bg-light rounded shadow login">
        <h1>{t("login.title")}</h1>
        <form onSubmit={handleFormSubmit}>
          <label htmlFor="email">{t("login.email")}:</label>
          <input
            type="email"
            id="email"
            value={email}
            placeholder={t("login.emailPlaceholder")}
            onChange={(e) => setEmail(e.target.value)}
            required
          />

          <label htmlFor="password">{t("login.password")}:</label>
          <input
            type="password"
            id="password"
            value={password}
            placeholder={t("login.passwordPlaceholder")}
            onChange={(e) => setPassword(e.target.value)}
            required
          />

          <HCaptcha sitekey={hcaptchaSiteKey} onVerify={handleCaptchaVerify} />

          {error && (
            <div className="container-title">
              <label className="text-danger">{error}</label>
            </div>
          )}

          <button type="submit">{t("login.loginButton")}</button>
        </form>
      </div>
    </Layout>
  );
};

export default Login;
