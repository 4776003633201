import React from 'react';
import { Link } from 'react-router-dom';
import logoPlaceholder from "../assets/images/logo_text.png";

const Header = () => {
  return (
    <header className="header">
      <div className="blue-ribbon"></div>
      <div className="ribbon"></div>
      <Link to="/">
      <img src={logoPlaceholder} alt="Logo" className="logo" />
      </Link>
      <div className="ribbon"></div>
    </header>
  );
};

export default Header;