import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const WaitingList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    navigate("/pre-register");
  };

  return (
    <div className="waiting-list">
      <div className="p-4 bg-light rounded shadow">
        <h2>{t("waitingList.title")}</h2>
        <p>{t("waitingList.description")}</p>

        <Button
          variant="success"
          type="submit"
          className="w-100 btn btn-lg btn-primary"
          onClick={handleSubmit}
        >
          {t("preRegister.registerButton")}
        </Button>
      </div>
    </div>
  );
};

export default WaitingList;
