import React from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ImageNotFound from "../assets/images/not_found.png";
import { useNavigate } from "react-router-dom";

import Layout from "./Layout";

const NotFound = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    navigate("/");
  };
  
  return (
    <Layout>
      <div className="container">
        <div className="p-4 bg-light rounded shadow not-found">
          <div className="center-content">
            <div className="notfound-column not-found-content">
              <h1>{t("notFound.title")}</h1>
              <p>{t("notFound.description")}</p>

              <Button
                variant="success"
                type="submit"
                className="w-100 btn btn-lg btn-primary"
                onClick={handleSubmit}
              >
                {t("common.backToHome")}
              </Button>
              <br />
            </div>

            <div className="image-column">
              <img
                src={ImageNotFound}
                alt="Placeholder"
                className="img-notfound"
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default NotFound;
