import React from "react";
import Layout from "./Layout";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function CookiePolicy() {
  const { t } = useTranslation();
  return (
    <Layout>
      <div className="container">
        <div className="p-4 bg-light rounded shadow cookie-policy">
          <h1 className="container-title">{t("cookiePolicy.title")}</h1>
          <p>{t("cookiePolicy.effectDate")}</p>
          <p>{t("cookiePolicy.welcome")}</p>
          <ol>
            <li>
              <h3>{t("cookiePolicy.title1")}</h3>
              <p>{t("cookiePolicy.desc1")}</p>
            </li>
            <li>
              <h3>{t("cookiePolicy.title2")}</h3>
              <div>
                {t("cookiePolicy.desc2")}
                <ul>
                  <li>{t("cookiePolicy.desc2opt1")}</li>
                  <li>{t("cookiePolicy.desc2opt2")}</li>
                  <li>{t("cookiePolicy.desc2opt3")}</li>
                  <li>{t("cookiePolicy.desc2opt4")}</li>
                </ul>
              </div>
            </li>
            <li>
              <h3>{t("cookiePolicy.title3")}</h3>
              <div>
                {t("cookiePolicy.desc3")}
                <ul>
                  <li>{t("cookiePolicy.desc3opt1")}</li>
                  <li>{t("cookiePolicy.desc3opt2")}</li>
                  <li>{t("cookiePolicy.desc3opt3")}</li>
                  <li>{t("cookiePolicy.desc3opt4")}</li>
                </ul>
              </div>
            </li>
            <li>
              <h3>{t("cookiePolicy.title4")}</h3>
              <p>{t("cookiePolicy.desc4")}</p>
            </li>
            <li>
              <h3>{t("cookiePolicy.title5")}</h3>
              <p>{t("cookiePolicy.desc5")}</p>
            </li>
            <li>
              <h3>{t("cookiePolicy.title6")}</h3>
              <p>
                {t("cookiePolicy.desc6")}{" "}
                <Link to="/contact">{t("footer.contact")}</Link>
              </p>
            </li>
          </ol>
        </div>
        <div className="ribbon"></div>
      </div>
    </Layout>
  );
}

export default CookiePolicy;
