import React, { useState, useEffect, useRef } from "react";
import Layout from "./Layout";
import axios from "axios";
import { ENDPOINTS } from "../config/apiConfig";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import cookies from "js-cookies";
import { Decrypt, Encrypt } from "../config/cryptor";

function SurveyForm() {
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();

  const [validated, setValidated] = useState([]);
  const [isValid, setIsValid] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    code: "",
    difficulties: "",
    additionalFeatures: "",
    problems: "",
    satisfaction: "",
    recommend: false,
    comments: "",
  });

  const effectRan = useRef(false);

  useEffect(() => {
    const userInfo = Decrypt(cookies.getItem("USER-INFO"));
    const formUserInfo = {
      code: searchParams.get("c"),
      userInfo: userInfo,
    };

    const fetchTokenValidInvitation = async () => {
      const axiosInstanceApp = axios.create({
        withCredentials: true,
        baseURL: process.env.REACT_APP_BASE_URL,
        headers: {
          Extra: process.env.REACT_APP_EXTRA,
        },
      });

      await axiosInstanceApp
        .get(`${ENDPOINTS.APP}`)
        .then((response) => {
          const data = JSON.parse(Decrypt(response.data));
          cookies.setItem("TOKEN", data.token);
          cookies.setItem("X-CSRF-TOKEN", data.csrf);
          fetchValidSurvey();
        })
        .catch((error) => {
          //console.error("Error fetching token:", error);
        });
    };

    const fetchValidSurvey = async () => {
      const axiosInstance = axios.create({
        baseURL: process.env.REACT_APP_BASE_URL,
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${cookies.getItem("TOKEN")}`,
          "x-access-token": cookies.getItem("TOKEN"),
          "X-CSRF-TOKEN": cookies.getItem("X-CSRF-TOKEN"),
          "Content-Type": "application/json",
        },
      });

      const cryptedData = Encrypt(formUserInfo);

      await axiosInstance
        .post(`${ENDPOINTS.GET_SURVEY}`, {
          data: cryptedData,
        })
        .then((response) => {
          const data = JSON.parse(Decrypt(response.data));
          const isValidResponse = data.error;
          setValidated(isValidResponse);
          setIsValid(!data.error.isError);
        })
        .catch((error) => {
          if (error.response.status === 401) {
            fetchTokenValidInvitation("valid");
          }
        });
    };

    if (!effectRan.current) {
      if (validated.length === 0) {
        if (searchParams.get("c") !== null) {
          const CODE = "code";
          setFormData({ ...formData, [CODE]: searchParams.get("c") });
          fetchTokenValidInvitation();
        }
      }
    }

    return () => (effectRan.current = true);
  }, [validated, searchParams, formData]);

  const handleChange = (e) => {
    const { name, value, type } = e.target;

    if (type === "radio") {
      setFormData({ ...formData, [name]: value });
    }
    if (type === "text") {
      setFormData({ ...formData, [name]: value });
    }
    if (type === "textarea") {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const CODE = "code";
      setFormData({ ...formData, [CODE]: searchParams.get("c") });

      if (formData.difficulties === "") {
        setError(t("surveyForm.selectOption"));
        return;
      }

      if (formData.additionalFeatures === "") {
        setError(t("surveyForm.selectOption"));
        return;
      }

      if (formData.problems === "") {
        setError(t("surveyForm.selectOption"));
        return;
      }

      if (formData.satisfaction === "") {
        setError(t("surveyForm.selectOption"));
        return;
      }

      setError("");

      const cryptedData = Encrypt(formData);

      const fetchToken = async () => {
        const axiosInstanceApp = axios.create({
          withCredentials: true,
          baseURL: process.env.REACT_APP_BASE_URL,
          headers: {
            Extra: process.env.REACT_APP_EXTRA,
          },
        });

        await axiosInstanceApp
          .get(`${ENDPOINTS.APP}`)
          .then((response) => {
            const data = JSON.parse(Decrypt(response.data));
            cookies.setItem("TOKEN", data.token);
            cookies.setItem("X-CSRF-TOKEN", data.csrf);
            sendData();
          })
          .catch((error) => {
            //console.error("Error fetching token:", error);
          });
      };

      const sendData = async () => {
        const axiosInstance = axios.create({
          baseURL: process.env.REACT_APP_BASE_URL,
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${cookies.getItem("TOKEN")}`,
            "x-access-token": cookies.getItem("TOKEN"),
            "Content-Type": "application/json",
            "X-CSRF-TOKEN": cookies.getItem("X-CSRF-TOKEN"),
          },
        });
        await axiosInstance
          .post(`${ENDPOINTS.SEND_SURVEY}`, {
            data: cryptedData,
          })
          .then((response) => {
            const data = JSON.parse(Decrypt(response.data));
            const error = data.error;
            if (!error.isError) {
              navigate("/survey-form-success");
            } else {
              setError(error.message);
            }
          });
      };

      fetchToken();
    } catch (error) {
      //console.error("Error al enviar los datos:", error);
    }
  };

  if (isValid) {
    if (validated.length !== 0) {
      if (validated.isError) {
        return (
          <div className="container">
            <div className="p-4 bg-light rounded shadow">
              <h4>{t("surveyForm.error")}</h4>
              <Form.Text className="text-danger">
                <p>{t("invalidMessage.invalidMessage")}</p>
              </Form.Text>
            </div>
          </div>
        );
      } else {
        return (
          <Layout>
            <div className="container">
              <div className="p-4 bg-light rounded shadow survey-form">
                <h2>{t("surveyForm.title")}</h2>
                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <label>{t("surveyForm.difficulties")}</label>
                    <br />
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="difficulties"
                        id="no"
                        value="No"
                        onChange={handleChange}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="difficulties"
                      >
                        {t("surveyForm.no")}
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="difficulties"
                        id="yes"
                        value="yesDifficulties"
                        onChange={handleChange}
                      />
                      <label className="form-check-label" htmlFor="other">
                        {t("surveyForm.yesSpecify")}
                      </label>
                      {formData.difficulties !== "No" && (
                        <input
                          type="text"
                          className="form-control"
                          name="difficulties"
                          onChange={handleChange}
                        />
                      )}
                    </div>
                  </div>
                  <div className="mb-3">
                    <label>{t("surveyForm.otherFeature")}</label>
                    <br />
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="additionalFeatures"
                        id=""
                        value="No"
                        onChange={handleChange}
                      />
                      <label className="form-check-label" htmlFor="navigation">
                        {t("surveyForm.no")}
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="additionalFeatures"
                        id="other"
                        value="Yes"
                        onChange={handleChange}
                      />
                      <label className="form-check-label" htmlFor="other">
                        {t("surveyForm.yesSpecify")}
                      </label>
                      {formData.additionalFeatures !== "No" && (
                        <input
                          type="text"
                          className="form-control"
                          name="additionalFeatures"
                          onChange={handleChange}
                        />
                      )}
                    </div>
                  </div>
                  <div className="mb-3">
                    <label>{t("surveyForm.problems")}</label>
                    <br />
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="problems"
                        id="noProblems"
                        value="No"
                        onChange={handleChange}
                      />
                      <label className="form-check-label" htmlFor="noProblems">
                        {t("surveyForm.no")}
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="problems"
                        id="yesProblems"
                        value="Si"
                        onChange={handleChange}
                      />
                      <label className="form-check-label" htmlFor="yesProblems">
                        {t("surveyForm.yesSpecify")}
                      </label>
                      {formData.problems !== "No" && (
                        <input
                          type="text"
                          className="form-control"
                          name="problems"
                          onChange={handleChange}
                        />
                      )}
                    </div>
                  </div>
                  <div className="mb-3">
                    <label>{t("surveyForm.satisfaction")}</label>
                    <br />
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="satisfaction"
                        id="excelente"
                        value="Excelente"
                        onChange={handleChange}
                      />
                      <label className="form-check-label" htmlFor="excelente">
                        {t("surveyForm.excellent")}
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="satisfaction"
                        id="buena"
                        value="Buena"
                        onChange={handleChange}
                      />
                      <label className="form-check-label" htmlFor="buena">
                        {t("surveyForm.good")}
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="satisfaction"
                        id="regular"
                        value="Regular"
                        onChange={handleChange}
                      />
                      <label className="form-check-label" htmlFor="regular">
                        {t("surveyForm.regular")}
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="satisfaction"
                        id="mala"
                        value="Mala"
                        onChange={handleChange}
                      />
                      <label className="form-check-label" htmlFor="mala">
                        {t("surveyForm.bad")}
                      </label>
                    </div>
                  </div>
                  <div className="mb-3">
                    <label>{t("surveyForm.recommend")}</label>
                    <br />
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="recommend"
                        id="recomendarSi"
                        value="true"
                        onChange={handleChange}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="recomendarSi"
                      >
                        {t("surveyForm.yes")}
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="recommend"
                        id="recomendarNo"
                        value="false"
                        onChange={handleChange}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="recomendarNo"
                      >
                        {t("surveyForm.no")}
                      </label>
                    </div>
                  </div>
                  <div className="mb-3">
                    <label>{t("surveyForm.comments")}</label>
                    <br />
                    <textarea
                      className="form-control"
                      name="comments"
                      rows="3"
                      onChange={handleChange}
                    ></textarea>
                  </div>
                  {error && (
                    <div className="container-title">
                      <Form.Text className="text-danger">{error}</Form.Text>
                    </div>
                  )}
                  <button
                    type="submit"
                    className="w-100 btn btn-lg btn-primary btn btn-success"
                  >
                    {t("surveyForm.send")}
                  </button>
                </form>
              </div>
              <div className="ribbon"></div>
            </div>
          </Layout>
        );
      }
    } else {
      return (
        <div className="container">
          <div className="p-4 bg-light rounded shadow">
            <h4>{t("surveyForm.error")}</h4>
            <Form.Text className="text-danger">
              <p>{t("surveyForm.invalidMessage")}</p>
            </Form.Text>
          </div>
        </div>
      );
    }
  } else {
    return (
      <div className="container">
        <div className="p-4 bg-light rounded shadow">
          <h4>{t("surveyForm.error")}</h4>
          <Form.Text className="text-danger">
            <p>{t("surveyForm.invalidMessage")}</p>
          </Form.Text>
        </div>
      </div>
    );
  }
}

export default SurveyForm;
