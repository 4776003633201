// cryptor.js
import CryptoJS from "crypto-js";

export function Encrypt(decrypted) {
  return CryptoJS.AES.encrypt(
    JSON.stringify(decrypted),
    process.env.REACT_APP_WEB
  ).toString();
};


export function Decrypt(encrypted) {
    const bytes = CryptoJS.AES.decrypt(encrypted, process.env.REACT_APP_WEB);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
}
