import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { ENDPOINTS } from "../config/apiConfig";
import { Form, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import Layout from "./Layout";
import { registerLocale } from "react-datepicker";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import cookies from "js-cookies";
import { Decrypt, Encrypt } from "../config/cryptor";
import "react-datepicker/dist/react-datepicker.css";
import { es } from "date-fns/locale/es";

registerLocale("es", es);

const RecoveryPassword = () => {
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [validated, setValidated] = useState([]);
  const [userInfo, setUserInfo] = useState([]);
  const [wait, setWait] = useState(0);
  const [isBanned, setIsBanned] = useState(false);
  const [countdown, setCountdown] = useState("");
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
  const navigate = useNavigate();

  const hcaptchaSiteKey = process.env.REACT_APP_HCAPTCHA_SITE_KEY;

  const handleCaptchaVerify = (token) => {
    setIsCaptchaVerified(true);
  };

  const effectRan = useRef(false);

  useEffect(() => {
    const fetchToken = async () => {
      const axiosInstanceApp = axios.create({
        withCredentials: true,
        baseURL: process.env.REACT_APP_BASE_URL,
        headers: {
          Extra: process.env.REACT_APP_EXTRA,
        },
      });

      await axiosInstanceApp
        .get(`${ENDPOINTS.APP}`)
        .then((response) => {
          const data = JSON.parse(Decrypt(response.data));
          cookies.setItem("TOKEN", data.token);
          cookies.setItem("X-CSRF-TOKEN", data.csrf);
          fetchIpBanned();
        })
        .catch((error) => {
          //console.error("Error fetching token:", error);
        });
    };

    const fetchIpBanned = async () => {
      const responseIpInfo = Decrypt(cookies.getItem("USER-INFO"));
      setUserInfo(responseIpInfo.data);

      const axiosInstance = await axios.create({
        baseURL: process.env.REACT_APP_BASE_URL,
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${cookies.getItem("TOKEN")}`,
          "x-access-token": cookies.getItem("TOKEN"),
          "Content-Type": "application/json",
          "X-CSRF-TOKEN": cookies.getItem("X-CSRF-TOKEN"),
        },
      });

      const cryptedData = Encrypt(responseIpInfo);

      await axiosInstance
        .post(`${ENDPOINTS.INTENTION}`, {
          data: cryptedData,
        })
        .then((response) => {
          const data = JSON.parse(Decrypt(response.data));
          const responseError = data;

          if (!responseError.error.isError) {
            setWait(responseError.missingMinutes);

            const endDate = new Date();
            endDate.setMinutes(
              endDate.getMinutes() - responseError.missingMinutes
            );

            const timer = setInterval(() => {
              const now = new Date();
              const remainingTime = endDate.getTime() - now.getTime();
              const minutes = Math.floor(
                (remainingTime % (1000 * 60 * 60)) / (1000 * 60)
              );
              const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);

              if (remainingTime <= 0) {
                clearInterval(timer);
              } else {
                setCountdown(`${minutes}m ${seconds}s`);
              }
            }, 1000);
          } else {
            if (responseError.error.message === "Banned") {
              setIsBanned(true);
            }
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            fetchToken();
          }
        });
    };

    if (effectRan.current) {
      fetchToken();
    }

    return () => (effectRan.current = true);
  }, []);

  useEffect(() => {
    const userInfo = Decrypt(cookies.getItem("USER-INFO"));
    const formUserInfo = {
      email: searchParams.get("email"),
      code: searchParams.get("code"),
      userInfo: userInfo,
    };

    const fetchTokenValidInvitation = async () => {
      const axiosInstanceApp = axios.create({
        withCredentials: true,
        baseURL: process.env.REACT_APP_BASE_URL,
        headers: {
          Extra: process.env.REACT_APP_EXTRA,
        },
      });

      await axiosInstanceApp
        .get(`${ENDPOINTS.APP}`)
        .then((response) => {
          const data = JSON.parse(Decrypt(response.data));
          cookies.setItem("TOKEN", data.token);
          cookies.setItem("X-CSRF-TOKEN", data.csrf);
          fetchValidInvitation();
        })
        .catch((error) => {
          //console.error("Error fetching token:", error);
        });
    };

    const fetchValidInvitation = async () => {
      const axiosInstance = axios.create({
        baseURL: process.env.REACT_APP_BASE_URL,
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${cookies.getItem("TOKEN")}`,
          "x-access-token": cookies.getItem("TOKEN"),
          "X-CSRF-TOKEN": cookies.getItem("X-CSRF-TOKEN"),
          "Content-Type": "application/json",
        },
      });

      const cryptedData = Encrypt(formUserInfo);

      await axiosInstance
        .post(`${ENDPOINTS.GET_RECOVERY}`, {
          data: cryptedData,
        })
        .then((response) => {
          const data = JSON.parse(Decrypt(response.data));
          const isValidResponse = data.error;
          setValidated(isValidResponse);
        })
        .catch((error) => {
          if (error.response.status === 401) {
            fetchTokenValidInvitation("valid");
          }
        });
    };

    if (!effectRan.current) {
      if (validated.length === 0) {
        if (
          searchParams.get("email") !== null &&
          searchParams.get("code") !== null
        ) {
          fetchTokenValidInvitation();
        }
      }
    }

    return () => (effectRan.current = true);
  }, [validated, searchParams, userInfo]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setError(t("recovery.errors.passwordNotMatch"));
      return;
    }

    if (!password) {
      setError(t("recovery.errors.password"));
      return;
    }

    if (!isCaptchaVerified) {
      setError(t("recovery.errors.verifyCaptcha"));
      return;
    }

    setError("");

    try {
      const formData = {
        email: searchParams.get("email"),
        password,
        code: searchParams.get("code"),
      };

      const cryptedData = Encrypt(formData);

      const fetchToken = async () => {
        const axiosInstanceApp = axios.create({
          withCredentials: true,
          baseURL: process.env.REACT_APP_BASE_URL,
          headers: {
            Extra: process.env.REACT_APP_EXTRA,
          },
        });

        await axiosInstanceApp
          .get(`${ENDPOINTS.APP}`)
          .then((response) => {
            const data = JSON.parse(Decrypt(response.data));
            cookies.setItem("TOKEN", data.token);
            cookies.setItem("X-CSRF-TOKEN", data.csrf);
            sendData();
          })
          .catch((error) => {
            //console.error("Error fetching token:", error);
          });
      };

      const sendData = async () => {
        const axiosInstance = axios.create({
          baseURL: process.env.REACT_APP_BASE_URL,
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${cookies.getItem("TOKEN")}`,
            "x-access-token": cookies.getItem("TOKEN"),
            "Content-Type": "application/json",
            "X-CSRF-TOKEN": cookies.getItem("X-CSRF-TOKEN"),
          },
        });
        await axiosInstance
          .post(`${ENDPOINTS.RECOVER}`, {
            data: cryptedData,
          })
          .then((response) => {
            const data = JSON.parse(Decrypt(response.data));
            const error = data.error;
            if (!error.isError) {
              navigate("/recovery-success");
            } else {
              setError(error.message);
            }
          });
      };

      fetchToken();
    } catch (error) {
      //console.error("Error al enviar los datos:", error);
    }
  };

  if (!isBanned) {
    if (wait >= 0) {
      if (validated.length !== 0) {
        if (validated.isError) {
          return (
            <div className="container">
              <div className="p-4 bg-light rounded shadow">
                <h4>Error</h4>
                <Form.Text className="text-danger">
                  <p>{t("recovery.errors.invalidMessage")}</p>
                </Form.Text>
              </div>
            </div>
          );
        } else {
          return (
            <Layout>
              <div className="container">
                <div className="p-4 bg-light rounded shadow register">
                  <Form onSubmit={handleSubmit}>
                    <h2>{t("recovery.title")}</h2>

                    <div className="register-list">
                      <Form.Group controlId="email" className="form mb-3">
                        <Form.Label>{t("recovery.email")}</Form.Label>
                        <Form.Control
                          value={searchParams.get("email")}
                          type="text"
                          placeholder={t("recovery.emailPlaceholder")}
                          required
                          readOnly={true}
                        />
                      </Form.Group>

                      <Form.Group controlId="password" className="form mb-3">
                        <Form.Label>{t("recovery.password")}</Form.Label>
                        <Form.Control
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          type="password"
                          placeholder={t("recovery.passwordPlaceholder")}
                          required
                        />
                      </Form.Group>

                      <Form.Group
                        controlId="confirmPassword"
                        className="form mb-3"
                      >
                        <Form.Label>{t("recovery.confirmPassword")}</Form.Label>
                        <Form.Control
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          type="password"
                          placeholder={t("recovery.confirmPasswordPlaceholder")}
                          required
                        />
                      </Form.Group>

                      <Form.Group
                        controlId="verifyCaptcha"
                        className="form mb-3"
                      >
                        <HCaptcha
                          sitekey={hcaptchaSiteKey}
                          onVerify={handleCaptchaVerify}
                        />
                      </Form.Group>

                      {error && (
                        <div className="container-title">
                          <Form.Text className="text-danger">{error}</Form.Text>
                        </div>
                      )}
                      <Button
                        variant="success"
                        type="submit"
                        className="w-100 btn btn-lg btn-primary"
                      >
                        {t("recovery.recoveryButton")}
                      </Button>
                    </div>
                  </Form>
                </div>
                <div className="ribbon"> </div>
              </div>
            </Layout>
          );
        }
      } else {
        return (
          <div className="container">
            <div className="p-4 bg-light rounded shadow">
              <h4>Error</h4>
              <Form.Text className="text-danger">
                <p>{t("register.errors.invalidMessage")}</p>
              </Form.Text>
            </div>
          </div>
        );
      }
    } else {
      return (
        <div className="container">
          <div className="p-4 bg-light rounded shadow">
            <h4>Error</h4>
            <Form.Text className="text-danger">
              <p>
                {t("register.errors.tryAgainIn")} {countdown}
              </p>
            </Form.Text>
          </div>
        </div>
      );
    }
  } else {
    return (
      <div className="container">
        <div className="p-4 bg-light rounded shadow">
          <h4>Error</h4>
          <Form.Text className="text-danger">
            <p>{t("register.errors.banned")}</p>
          </Form.Text>
        </div>
      </div>
    );
  }
};

export default RecoveryPassword;
