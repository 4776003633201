import React from "react";
import ReactDOM from "react-dom/client";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/styles.css";
import App from "./App";
import { I18nextProvider } from "react-i18next";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import enTranslation from "./locales/en.json";
import esTranslation from "./locales/es.json";
import reportWebVitals from "./reportWebVitals";
import CookieConsentForm from "./components/CookieConsentForm";
import Modal from "react-modal";


Modal.setAppElement("#root"); // Cambia '#root' por el selector de tu elemento raíz

i18n
  .use(LanguageDetector) // Detecta automáticamente el idioma del navegador
  .init({
    resources: {
      en: { translation: enTranslation },
      es: { translation: esTranslation },
    },
    fallbackLng: "en", // Idioma predeterminado si no se encuentra una traducción
    interpolation: { escapeValue: false },
  });

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <App />
      <CookieConsentForm />      
    </I18nextProvider>
  </React.StrictMode>
);

reportWebVitals();
