import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  FaFacebook,
  FaInstagram,
  FaTiktok,
  FaYoutube,
  FaLinkedin,
  FaGooglePlay,
} from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

const Footer = () => {
  const { t } = useTranslation();
  const [showDropdown, setShowDropdown] = useState(false);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  return (
    <footer className="footer">
      <div className="ribbon"> </div>
      <div className="ribbon"></div>
      <h2>
        <a
          href="https://play.google.com/store/apps/details?id=com.preiseek.app"
          target="_blank"
          rel="noopener noreferrer"
          className="social-icon"
        >
          <FaGooglePlay />
        </a>{" "}
        <a
          href="https://www.facebook.com/preiseek"
          target="_blank"
          rel="noopener noreferrer"
          className="social-icon"
        >
          <FaFacebook />
        </a>{" "}
        <a
          href="https://www.instagram.com/preiseek"
          target="_blank"
          rel="noopener noreferrer"
          className="social-icon"
        >
          <FaInstagram />
        </a>{" "}
        <a
          href="https://tiktok.com/@preiseek"
          target="_blank"
          rel="noopener noreferrer"
          className="social-icon"
        >
          <FaTiktok />
        </a>{" "}
        <a
          href="https://www.youtube.com/@preiseek"
          target="_blank"
          rel="noopener noreferrer"
          className="social-icon"
        >
          <FaYoutube />
        </a>{" "}
        <a
          href="https://www.x.com/preiseek"
          target="_blank"
          rel="noopener noreferrer"
          className="social-icon"
        >
          <FaXTwitter />
        </a>{" "}
        <a
          href="https://mx.linkedin.com/company/preiseek"
          target="_blank"
          rel="noopener noreferrer"
          className="social-icon"
        >
          <FaLinkedin />
        </a>
      </h2>
      <div className="ribbon"></div>
      <div className="footer-links">
        <div className="footer-list">
          <li>
            <Link to="/">{t("footer.home")}</Link>
          </li>
          <li>
            <Link to="/about">{t("footer.about")}</Link>
          </li>
          <li>
            <Link to="/contact">{t("footer.contact")}</Link>
          </li>
          <li>
            <Dropdown show={showDropdown} onToggle={toggleDropdown}>
              <Dropdown.Toggle as="a" className="dropdown-toggle">
                {t("footer.policy")}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item href="/terms">{t("footer.terms")}</Dropdown.Item>
                <Dropdown.Item href="/privacy">
                  {t("footer.privacy")}
                </Dropdown.Item>
                <Dropdown.Item href="/cookies">
                  {t("footer.cookies")}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </li>
          <li>
            <div>
              <p>© {new Date().getFullYear()} Preiseek</p>
            </div>
          </li>
        </div>
      </div>
      <div className="ribbon"></div>
      <div className="footer-ribbon">
        <div className="green-ribbon"></div>
      </div>
    </footer>
  );
};

export default Footer;
