import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import axios from "axios";
import { ENDPOINTS } from "../config/apiConfig";
import { Decrypt, Encrypt } from "../config/cryptor";
import cookies from "js-cookies";

const CookieConsentForm = () => {
  const { t } = useTranslation();

  const [userInfo, setUserInfo] = useState([]);

  const [cookiesAccepted, setCookiesAccepted] = useState(
    localStorage.getItem("cookieConsent") === "true"
  );
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [isAnalyticsCookiesEnabled, setIsAnalyticsCookiesEnabled] =
    useState(true);
  const [isMarketingCookiesEnabled, setIsMarketingCookiesEnabled] =
    useState(true);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const fetchIp = async () => {
    const responseIpInfo = await axios.get("https://ipinfo.io/json");
    setUserInfo(responseIpInfo.data);

    const data = await Encrypt(responseIpInfo.data);
    cookies.setItem("USER-INFO", data);
  };

  const handleConsentSubmit = async (e) => {
    e.preventDefault();

    localStorage.setItem("cookieConsent", "true");
    setCookiesAccepted(true);

    const fetchToken = async () => {
      const axiosInstanceApp = axios.create({
        withCredentials: true,
        baseURL: process.env.REACT_APP_BASE_URL,
        headers: {
          Extra: process.env.REACT_APP_EXTRA,
        },
      });

      await axiosInstanceApp
        .get(`${ENDPOINTS.APP}`)
        .then((response) => {
          const data = JSON.parse(Decrypt(response.data));
          cookies.setItem("TOKEN", data.token);
          cookies.setItem("X-CSRF-TOKEN", data.csrf);
          sendData();
        })
        .catch((error) => {
          //console.error("Error fetching token:", error);
        });
    };

    const sendData = async () => {
      const formData = {
        necessaryAccept: true,
        analisysAccept: isAnalyticsCookiesEnabled,
        marketingAccept: isMarketingCookiesEnabled,
        userData: userInfo,
      };

      const axiosInstance = axios.create({
        baseURL: process.env.REACT_APP_BASE_URL,
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${cookies.getItem("TOKEN")}`,
          "x-access-token": cookies.getItem("TOKEN"),
          "Content-Type": "application/json",
          "X-CSRF-TOKEN": cookies.getItem("X-CSRF-TOKEN"),
        },
      });

      const cryptedData = Encrypt(formData);
      await axiosInstance
        .post(`${ENDPOINTS.COOKIES}`, {
          data: cryptedData,
        })
        .then((response) => {
          const data = JSON.parse(Decrypt(response.data));
          const error = data.error;
          if (!error.isError) {
            closeModal();
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            fetchToken();
          }
        });
    };
    fetchToken();
  };

  useEffect(() => {
    if (userInfo.length === 0) {
      fetchIp();
    }
  }, [userInfo]);

  return (
    <div>
      {!cookiesAccepted && (
        <div className="cookie-tab" onClick={openModal}>
          {t("cookieConsent.accept")}
        </div>
      )}

      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel={t("cookieConsent.title")}
        className="ReactModal__Content"
        overlayClassName="ReactModal__Overlay"
        shouldCloseOnOverlayClick={true}
        ariaHideApp={false} // Agrega esta línea
      >
        <h2>{t("cookieConsent.title")}</h2>
        <p>{t("cookieConsent.desc")}</p>
        <label>
          <input
            type="checkbox"
            checked={true} // Deshabilitado y siempre marcado
            disabled
          />
          {t("cookieConsent.optRequired")}
        </label>
        <label>
          <input
            type="checkbox"
            checked={isAnalyticsCookiesEnabled}
            onChange={() =>
              setIsAnalyticsCookiesEnabled(!isAnalyticsCookiesEnabled)
            }
          />
          {t("cookieConsent.optAnalytic")}
        </label>
        <label>
          <input
            type="checkbox"
            checked={isMarketingCookiesEnabled}
            onChange={() =>
              setIsMarketingCookiesEnabled(!isMarketingCookiesEnabled)
            }
          />
          {t("cookieConsent.optMarketing")}
        </label>

        <button onClick={handleConsentSubmit}>
          {t("cookieConsent.accept")}
        </button>
      </Modal>
    </div>
  );
};

export default CookieConsentForm;
