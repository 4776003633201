// apiConfig.js
export const ENDPOINTS = {
  APP: "/app",
  PRE_REGISTER: "/pre-register",
  CONTACT: "/contact",
  COUNTRIES: "/countries",
  STATES: "/states",
  POSTAL_CODE: "/postalCode",
  GET_INVITATION: "/getInvitation",
  INTENTION: "/intention",
  REGISTER: "/register",
  LOGIN: "/login",
  COOKIES: "/cookiesAcceptance",
  GET_RECOVERY: "/getRecovery",
  RECOVER: "/recover",
  GET_SURVEY: "/survey",
  SEND_SURVEY: "/sendSurvey",
  UNSUSCRIBE: "/unsuscribe"
};
